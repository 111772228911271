<template>
  <keep-alive>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </keep-alive>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
</style>
