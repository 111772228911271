<template>
  <div class="wapnone fk_service">
    <ul>
      <li class="fk_service_box fk_service_zmkiqp">
        <div class="fk_service_zmkiqp_cont" @click="openFull"> <span class="fk_service_triangle"></span>切换全屏</div>
      </li>
      <li class="fk_service_box fk_service_upward">
        <div class="fk_service_upward_cont go-up" >
          <a rel="go-top"> <span class="fk_service_triangle"></span> <span> 返回顶部 </span></a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'floatingBar',
  data() {
    return {
      isFull: true,
    }
  },
  methods:{
    openFull(){
      if(this.isFull){
        this.fullScanner()
      }else{
        this.exitfullscreen()
      }
      this.isFull = !this.isFull
    },
    fullScanner(){
      var docElm = document.documentElement;
      //W3C
      if(docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }
      //FireFox
      else if(docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      //Chrome等
      else if(docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      //IE11
      else if(docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    },
    exitfullscreen() { 
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if(document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }
}
</script>

<style>
.fk_service ul {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
}
.fk_service {
  max-height: 232px;
  position: fixed;
  right: 10px;
  top: 85%;
  font-family: 寰蒋闆呴粦;
  font-size: 14px;
  color: rgb(36, 53, 88);
  z-index: 10000;
}
.fk_service li {
  list-style-type: none;
}
.fk_service_zmkiqp{
  z-index: 555;
  background: url('/assets/images/fullScanner.png') no-repeat center center #fff;
  background-size: 50%50%;
}
.fk_service_zmkiqp:hover {
    border: 0;
    background: url(/assets/images/fullScanner_hour.png) no-repeat center center #4f7cfc;
    background-size: 50%50%;
    cursor: pointer;
}
.fk_service_box{
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
}
.fk_service li>div {
    box-sizing: border-box;
    box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
}
.fk_service_zmkiqp_cont {
  width: 100px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  border-radius: 5px;
  right: 62px;
  text-align: center;
  position: absolute;
  display: none;
  border: 1px solid #e1e6ec;
}
.fk_service_triangle {
    top: 12px;
    right: -11px;
    position: absolute;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 11px solid #e1e6ec;
    z-index: 1010;
}
.fk_service_upward {
  background: url(/assets/images/icon_all.png) no-repeat -25px -157px #fff;
}
.fk_service_upward:hover {
  border: 0;
  background: url(/assets/images/icon_all.png) no-repeat -69px -157px #4f7cfc;
  cursor: pointer;
}
.fk_service_triangle::before {
  content: "\20";
  width: 80px;
  height: 45px;
  top: -20px;
  right: -52px;
  position: absolute;
  background: rgba(0, 0, 0, 0);
}
.fk_service_triangle::after {
  content: "\20";
  top: -6px;
  right: 1px;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  z-index: 1000;
}
.fk_service_upward:hover .fk_service_upward_cont {
    display: block;
    opacity: 1;
    transition: linear .2s;
    animation-name: fade-in;
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-delay: 0s;
}
.fk_service_zmkiqp:hover .fk_service_zmkiqp_cont {
  display: block;
  opacity: 1;
  transition: linear .2s;
  animation-name: fade-in;
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}
.fk_service_upward_cont {
  width: 84px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background: #fff;
  right: 62px;
  position: absolute;
  text-align: center;
  border: 1px solid #e1e6ec;
  display: none;
  opacity: 0;
}
.fk_service_upward_cont span {
    font-size: 14px;
}
.fk_service_triangle {
    top: 12px;
    right: -11px;
    position: absolute;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 11px solid #e1e6ec;
    z-index: 1010;
}
</style>